import React, { useState } from "react";

interface DropdownProps {
  sections: { title: string; items: string[] }[];
  onChange: (selectedItem: string) => void;
}

const NestedDropdown: React.FC<DropdownProps> = ({ sections, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState<number | null>(null);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  const handleSelect = (item: string) => {
    setSelectedItem(item);
    setIsOpen(false);
    setOpenSubMenu(null);
    onChange(item); // Передаём выбранный пункт
  };

  return (
    <div>
      {/* Кнопка с выбранным значением */}
      <button onClick={() => setIsOpen(!isOpen)}>
        {selectedItem || "Выберите шаблон"}
      </button>

      {/* Главное меню */}
      {isOpen && (
        <ul
          onMouseLeave={() => {
            setIsOpen(false);
            setOpenSubMenu(null);
          }}
        >
          {sections.map((section, index) => (
            <li
              key={index}
              onMouseEnter={() => setOpenSubMenu(index)}
              onMouseLeave={() => setOpenSubMenu(null)}
              style={{ position: "relative",listStyle:'none' }}
            >
              <button>{section.title} ➡</button>

              {/* Вложенное меню */}
              {openSubMenu === index && (
                <ul
                  style={{
                    position: "absolute",
                    left: "100%",
                    top: 0,
                    background: "white",
                    border: "1px solid black",
                    padding: "5px",
                    listStyle:'none'
                  }}
                >
                  {section.items.map((item, subIndex) => (
                    <li key={subIndex}>
                      <button onClick={() => handleSelect(item)}>{item}</button>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default NestedDropdown;
