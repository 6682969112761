import React, { useState, useEffect } from 'react';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';
import './AppStyled.css';
import { Login,Register,SaveData } from './registrForms';
import {UserList} from './Outalldata'
import { DocumentAttributes } from 'docx';
import { start } from 'repl';
import { ShowSubUsers } from './OutSubmessiveUsersData';
import { CreateSubmissiveUser } from './registrForms';
import { SaveSubUserData } from './registrForms';
import NestedDropdown from './htmllisttemmp';


const templates: { [key: string]: string } = {
  '0 - Punomoc za DOO i Odluku' :  '/templates/0 - Punomoc za DOO i Odluku/1.docx',
  '1_0 - Godišnja ponuda produženje' : '/templates/1_0 - Godišnja ponuda produženje/1.docx',
  '1_1 - Godišnja ponuda prvi' : '/templates/1_1 - Godišnja ponuda prvi/1.docx',
  '1_2 - Izjava za platu director' : '/templates/1_2 - Izjava za platu director/1.docx',
  '1_3 - Izjava za platu porodica' : '/templates/1_3 - Izjava za platu porodica/1.docx',
  '1_4 -  Zahtjev porezko uverenje' : '/templates/1_4 -  Zahtjev porezko uverenje/1.docx',
  '1_5 - Zahtjev_radna_knjizica' : '/templates/1_5 - Zahtjev_radna_knjizica/1.docx',
 
  '2_0 - Fond za zdravstveno osiguranje' : '/templates/2_0 - Fond za zdravstveno osiguranje/1.docx',
  '2_1 - JPR' : '/templates/2_1 - JPR/1.docx',                                                            
  '2_2 - Ovlašenje za porezku' : '/templates/2_2 - Ovlašenje za porezku/1.docx',
  '2_3 - Ugovor o radu' : '/templates/2_3 - Ugovor o radu/1.docx',
  '2_4 - Ugovor o radu Budva' : '/templates/2_4 - Ugovor o radu Budva/1.docx',
  '2_5 - Zahtjev na izvod iz CRPS-a' : '/templates/2_5 - Zahtjev na izvod iz CRPS-a/1.docx',
 
  '3_0 - Ovlaščenje za poštu' : '/templates/3_0 - Ovlaščenje za poštu/1.docx',
  '3_1 - Zahtjev za fiskalizaciju' : '/templates/3_1 - Zahtjev za fiskalizaciju/1.docx',                                                                                                  
  '3_2 - Zahtjev za token' : '/templates/3_2 - Zahtjev za token/1.docx',                        

/*   template_3_3 : '/templates/3_3 - Otvaranje racuna zahtjev FL Lovcen/1.docx',  */                              
  '3_4 - Zahtjev za otvaranje računa PL sve Lovcen' : '/templates/3_4 - Zahtjev za otvaranje računa PL sve Lovcen/1.docx',                          
 
 
  '4_0 - Izjava za raskid ugovora o radu' : '/templates/4_0 - Izjava za raskid ugovora o radu/1.docx',
  '4_1 - Otkaz ugovora o radu' : '/templates/4_1 - Otkaz ugovora o radu/1.docx',
  '4_2 - Sporazum o prestanku radnog odnosa' : '/templates/4_2 - Sporazum o prestanku radnog odnosa/1.docx',
 
  '5_0 - Pozajmica' : '/templates/5_0 - Pozajmica/1.docx',
  '5_1 - Token punomoć' : '/templates/5_1 - Token punomoć/1.docx',
  '5_2 - Virmani' : '/templates/5_2 - Virmani/1.docx',
  '5_3 - Zahtjev na poresko uverenje' : '/templates/5_3 - Zahtjev na poresko uverenje/1.docx',
  '5_4 - Pozajmica sa zavodnom pečatom':'/templates/5_4 - Pozajmica sa zavodnom pečatom/1.docx',

  '6_0 - Izjava likvidacija' : '/templates/6_0 - Izjava likvidacija/1.docx',
  '6_1 - Punomoć likvidacija' : './templates/6_1 - Punomoć likvidacija/1.docx',
}

export interface FormData {
  id:number|null;
  name: string;
  surName: string;
  fathName: string;
  birthDate: string;
  bd1: string;
  bd2: string;
  bd3: string;
  bd4: string;
  bd5: string;
  bd6: string;
  bd7: string;
  bd8: string;
  male: string; 
  female: string;
  tel: string;
  email: string;
  address: string;
  city:string;
  workBookNum: string;
  passNum: string;
  issueDate: string;
  issuingOrgan: string;
  birthPlace: string;
  jmbgNum: string;
  j1: string;
  j2: string;
  j3: string;
  j4: string;
  j5: string;
  j6: string;
  j7: string;
  j8: string;
  j9: string;
  j10: string;
  j11: string;
  j12: string;
  j13: string;
  jmbgFrom: string;
  jf1: string;
  jf2: string;
  jf3: string;
  jf4: string;
  jf5: string;
  jf6: string;
  jf7: string;
  jf8: string;
  jmbgTo: string;
  pib: string;
  compName: string;
  compAddr: string;
  compCity: string;
  compStreetHome:string;
  compMunicipal: string;
  compRegNum: string;
  compRegDate: string;
  rd1: string;
  rd2: string;
  rd3: string;
  rd4: string;
  rd5: string;
  rd6: string;
  rd7: string;
  rd8: string;
  billNum:string;
  compBillNum: string;
  famName1: string;
  famPassNum1: string;
  famMember1: string;
  famJmbgNum1: string;
  f11: string;
  f12: string;
  f13: string;
  f14: string;
  f15: string;
  f16: string;
  f17: string;
  f18: string;
  f19: string;
  f110: string;
  f111: string;
  f112: string;
  f113: string;
  famName2: string;
  famPassNum2: string;
  famMember2: string;
  famJmbgNum2: string;
  f21: string;
  f22: string;
  f23: string;
  f24: string;
  f25: string;
  f26: string;
  f27: string;
  f28: string;
  f29: string;
  f210: string;
  f211: string;
  f212: string;
  f213: string;
  famName3: string;
  famPassNum3: string;
  famMember3: string;
  famJmbgNum3: string;
  f31: string;
  f32: string;
  f33: string;
  f34: string;
  f35: string;
  f36: string;
  f37: string;
  f38: string;
  f39: string;
  f310: string;
  f311: string;
  f312: string;
  f313: string;
  famName4: string;
  famPassNum4: string;
  famMember4: string;
  famJmbgNum4: string;
  f41: string;
  f42: string;
  f43: string;
  f44: string;
  f45: string;
  f46: string;
  f47: string;
  f48: string;
  f49: string;
  f410: string;
  f411: string;
  f412: string;
  f413: string;
  famName5: string;
  famPassNum5: string;
  famMember5: string;
  famJmbgNum5: string;
  f51: string;
  f52: string;
  f53: string;
  f54: string;
  f55: string;
  f56: string;
  f57: string;
  f58: string;
  
  f59: string;
  f510: string;
  f511: string;
  f512: string;
  f513: string;
  currDate: string;
  termDate: string;
  month: string;
  f11_3: string;
  f21_3: string;
  f31_3: string;
  f41_3: string;
  f51_3: string;
  replaceBirthDate:string;
  replacePassDate:string;
  replaceJmbgFrom:string;
  replaceJmbgTo:string;
  replaceDateRegisterComp:string;
  replaceCurrDate:string;
  replaceTermDate:string;
  replaceIssueDate:string;
  p1:string;
  p2:string;
  p3:string;
  p4:string;
  p5:string;
  p6:string;
  p7:string;
  p8:string;
  p9:string;
  p10:string;
  p11:string;
  p12:string;
  p13:string;

  mailIndex:string;
  compMailIndex:string;
  bankName:string;
  billType:string;
}

const App: React.FC = () => {
  const [data, setData] = useState<FormData>({
      id:null,
      name: ' ',
      surName: ' ',
      fathName: ' ',
      birthDate: ' ',
      bd1: ' ',
      bd2: ' ',
      bd3: ' ',
      bd4: ' ',
      bd5: ' ',
      bd6: ' ',
      bd7: ' ',
      bd8: ' ',
      male: ' ',
      female: ' ',
      tel: ' ',
      email: ' ',
      address: ' ',
      city:' ',
      workBookNum: ' ',
      passNum: ' ',
      issueDate: ' ',
      issuingOrgan: ' ',
      birthPlace: ' ',
      jmbgNum: ' ',
      j1: ' ',
      j2: ' ',
      j3: ' ',
      j4: ' ',
      j5: ' ',
      j6: ' ',
      j7: ' ',
      j8: ' ',
      j9: ' ',
      j10: ' ',
      j11: ' ',
      j12: ' ',
      j13: ' ',
      jmbgFrom: ' ',
      jf1: ' ',
      jf2: ' ',
      jf3: ' ',
      jf4: ' ',
      jf5: ' ',
      jf6: ' ',
      jf7: ' ',
      jf8: ' ',
      jmbgTo: ' ',
      pib: ' ',
      compName: ' ',
      compAddr: ' ',
      compCity: ' ',
      compStreetHome: ' ',
      compMunicipal: ' ',
      compRegNum: ' ',
      compRegDate: ' ',
      rd1: ' ',
      rd2: ' ',
      rd3: ' ',
      rd4: ' ',
      rd5: ' ',
      rd6: ' ',
      rd7: ' ',
      rd8: ' ',
      compBillNum: ' ',
      billNum:' ',
      famName1: ' ',
      famPassNum1: ' ',
      famMember1: ' ',
      famJmbgNum1: ' ',
      f11: ' ',
      f12: ' ',
      f13: ' ',
      f14: ' ',
      f15: ' ',
      f16: ' ',
      f17: ' ',
      f18: ' ',
      f19: ' ',
      f110: ' ',
      f111: ' ',
      f112: ' ',
      f113: ' ',
      famName2: ' ',
      famPassNum2: ' ',
      famMember2: ' ',
      famJmbgNum2: ' ',
      f21: ' ',
      f22: ' ',
      f23: ' ',
      f24: ' ',
      f25: ' ',
      f26: ' ',
      f27: ' ',
      f28: ' ',
      f29: ' ',
      f210: ' ',
      f211: ' ',
      f212: ' ',
      f213: ' ',
      famName3: ' ',
      famPassNum3: ' ',
      famMember3: ' ',
      famJmbgNum3: ' ',
      f31: ' ',
      f32: ' ',
      f33: ' ',
      f34: ' ',
      f35: ' ',
      f36: ' ',
      f37: ' ',
      f38: ' ',
      f39: ' ',
      f310: ' ',
      f311: ' ',
      f312: ' ',
      f313: ' ',
      famName4: ' ',
      famPassNum4: ' ',
      famMember4: ' ',
      famJmbgNum4: ' ',
      f41: ' ',
      f42: ' ',
      f43: ' ',
      f44: ' ',
      f45: ' ',
      f46: ' ',
      f47: ' ',
      f48: ' ',
      f49: ' ',
      f410: ' ',
      f411: ' ',
      f412: ' ',
      f413: ' ',
      famName5: ' ',
      famPassNum5: ' ',
      famMember5: ' ',
      famJmbgNum5: ' ',
      f51: ' ',
      f52: ' ',
      f53: ' ',
      f54: ' ',
      f55: ' ',
      f56: ' ',
      f57: ' ',
      f58: ' ',
      f59: ' ',
      f510: ' ',
      f511: ' ',
      f512: ' ',
      f513: ' ',
      currDate: ' ',
      termDate: ' ',
      month: ' ',
      f11_3: ' ',
      f21_3: ' ',
      f31_3: ' ',
      f41_3: ' ',
      f51_3: ' ',
      replaceBirthDate:' ',
      replacePassDate:' ',
      replaceJmbgFrom:' ',
      replaceJmbgTo:' ',
      replaceDateRegisterComp:' ',
      replaceCurrDate:' ',
      replaceTermDate:' ',
      replaceIssueDate:' ',
      p1:' ',
      p2:' ',
      p3:' ',
      p4:' ',
      p5:' ',
      p6:' ',
      p7:' ',
      p8:' ',
      p9:' ',
      p10:' ',
      p11:' ',
      p12:' ',
      p13:' ',
      mailIndex:' ',
      compMailIndex:' ',
      bankName:' ',
      billType:' '
    });

/*     const [date,setDate]=useState({
      replaceBirthDate:'',
      replacePassDate:'',
      replaceJmbgFrom:'',
      replaceJmbgTo:'',
      replaceDateRegisterComp:'',
      replaceCurrDate:''
    }) */

  const [isUserLogin, setIsUserLogin]=useState(Boolean);
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const [requiredFields, setRequiredFields] = useState<string[]>([]);


  const handleSplitAndSet = (name:string,value:string,keys:string[])=> {
    const dateDigit = value.replace(/[^0-9]/g, '');
    const newValues: {[key:string]:string}={};

    keys.forEach((key,index) => {
      newValues[key]=dateDigit[index] || '';
    });
    setData(prevData => ({
      ...prevData,
      ...newValues, // добавляем новые значения в состояние
    }));

  }


  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    {setData(prevData => ({
      ...prevData, 
      [name]: value,
    })
  );}
  };

  const handleGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
  
    setData(prevData => ({
      ...prevData,
      male: name === 'male' ? 'V' : '-',  
      female: name === 'female' ? 'V' : '-',
    }));
  };

  const cities:Record<string,string>={
      'Podgorica':'81000',
      'Tuzi':'81206',
      'Golubovci':'81304',
      'Kolasin':'81210',
      'Danilovgrad':'81410',
      'Cetinje':'81250',
      'Niksic':'81400',
      'Bar':'85000',
      'Budva':'85310',
      'Tivat':'85320',
      'Kotor':'85330',
      'Herceg Novi':'85340',
      'Ulcinj':'85360'
  };
  const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedCity = event.target.value;
    setData(prevData => ({
      ...prevData,
      city: selectedCity,
      mailIndex: cities[selectedCity] || " ",
    }));
  };
  const handleCompCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedCity = event.target.value;
    setData(prevData => ({
      ...prevData,
      compCity: selectedCity,
      compMailIndex: cities[selectedCity] || " ",
    }));
  };

  interface FieldsCheck{
    [key:string]:string;
    bd1:string;
    j1:string;
    jf1:string;
    rd1:string;
    f11:string;
    f21:string;
    f31:string;
    f41:string;
    f51:string;
  }
  const handleTemplateChange = async (templateKey: string) => {
    setSelectedTemplate(templateKey);

    const templateFile = templates[templateKey];
    if (!templateFile) return;

    const response = await fetch(templateFile);
    const content = await response.arrayBuffer();

    const zip = new PizZip(content);
    const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true });

    const text = doc.getFullText();
    const matches = text.match(/\{(.*?)\}/g) || [];
    const fields = matches.map((match) => match.replace(/[{}]/g, ""));

    const PareFields: { [key: string]: string } = {
      bd1: "birthDate",
      j1: "jmbgNum",
      jf1: "jmbgFrom",
      rd1: "compRegDate",
      f11: "famJmbgNum1",
      f21: "famJmbgNum2",
      f31: "famJmbgNum3",
      f41: "famJmbgNum4",
      f51: "famJmbgNum5",
      p1: "pib",
    };

    for (const field of fields) {
      if (PareFields[field]) {
        fields.push(PareFields[field]);
      }
    }

    setRequiredFields(fields);
  };

  const replacedate=async(date:string)=>{ // переворачивание дат для корректрного отображения в документах
    try{
    let result:string;
    result=date.substring(8)+date.substring(4,8)+date.substring(0,4)
    return result
  }catch (error){
    console.log('error witch date replace',error,'date: ',date)
    let errorResult:string
    errorResult='ups with date'
    return errorResult
  }
  }

const generateDocument = async () => {
  if (selectedTemplate === '1_3 - Izjava za platu porodica') {
    console.log('work yemplate 1_3 - Izjava za platu porodica');

    setData(prevData => {
      const updatedData = {
        ...prevData,
        f11_3: prevData.famName1 !== ' ' ? ', putna isprava P RUS ' : ' ',
        f21_3: prevData.famName2 !== ' ' ? ', putna isprava P RUS ' : ' ',
        f31_3: prevData.famName3 !== ' ' ? ', putna isprava P RUS ' : ' ',
        f41_3: prevData.famName4 !== ' ' ? ', putna isprava P RUS ' : ' ',
        f51_3: prevData.famName5 !== ' ' ? ', putna isprava P RUS ' : ' ',
      };
      // После обновления данных сразу вызываем генерацию документа
      generateWordDocument(updatedData);
     return updatedData;
    });
  } else {
    // Ожидаем завершения всех асинхронных операций перед обновлением состояния
    const replaceBirthDate = await replacedate(data.birthDate);
    const replacePassDate = await replacedate(data.issueDate);
    const replaceJmbgFrom = await replacedate(data.jmbgFrom);
    const replaceJmbgTo = await replacedate(data.jmbgTo);
    const replaceDateRegisterComp = await replacedate(data.compRegDate);
    const replaceCurrDate = await replacedate(data.currDate);
    const replaceTermDate = await replacedate(data.termDate);
    const replaceIssueDate = await replacedate(data.issueDate)
    // Устанавливаем данные после завершения всех асинхронных операций +
    setData(prevData => ({
      ...prevData,
      replaceBirthDate,
      replacePassDate,
      replaceJmbgFrom,
      replaceJmbgTo,
      replaceDateRegisterComp,
      replaceCurrDate,
      replaceTermDate,
      replaceIssueDate
    }));

    // Генерация документа с обновлёнными данными
    generateWordDocument({
      ...data,
      replaceBirthDate,
      replacePassDate,
      replaceJmbgFrom,
      replaceJmbgTo,
      replaceDateRegisterComp,
      replaceCurrDate,
      replaceTermDate,
      replaceIssueDate
      
    });

  }
};

  
  const generateWordDocument = async (data:FormData) => {
    const templateFile = templates[selectedTemplate];
    if (!templateFile) return;
  
    const response = await fetch(`${templateFile}?v=${Date.now()}`);
    if (!response.ok) {
      console.error(`Failed to fetch template: ${response.statusText}`);
      return;
    }
    const content = await response.arrayBuffer();
  
    const zip = new PizZip(content);
    const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true });
  
    doc.render(data);
  
    const out = doc.getZip().generate({
      type: 'blob',
      mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });
  
    if(selectedTemplate==='template_1_5'||'template_4_0'){
    saveAs(out, `${templates[selectedTemplate]}_${data.name}_${data.surName}.docx`);
    }else if(selectedTemplate==='template_2_5'){
      saveAs(out, `${templates[selectedTemplate]}_${data.compName}.docx`);
    }else{
      saveAs(out, `${templates[selectedTemplate]}_${data.name}_${data.surName}_${data.compName}.docx`);
    }
  };
  




  const isFieldRequired = (fieldName: string) => requiredFields.includes(fieldName);
  

  const [showAppComp, setShowAppComp] = useState(false);

  const isUserLoginDataUpdate = (logged:boolean)=>{
    setIsUserLogin(logged)
  }
  
  const handleUserDataUpdate = (userData: any) => {

        setData(userData);
  
        if (userData.id === 1) {
          setShowAppComp(true);
        } else {
          setShowAppComp(false);
        }

    // Применяем разбивку для нужных полей, как если бы их вводили вручную
    if (userData.birthDate) {
      handleSplitAndSet('birthDate', userData.birthDate, ['bd1', 'bd2', 'bd3', 'bd4', 'bd5', 'bd6', 'bd7', 'bd8']);
    }
    if (userData.jmbgNum) {
      handleSplitAndSet('jmbgNum', userData.jmbgNum, ['j1', 'j2', 'j3', 'j4', 'j5', 'j6', 'j7', 'j8', 'j9', 'j10', 'j11', 'j12', 'j13']);
    }
    if (userData.jmbgFrom) {
      handleSplitAndSet('jmbgFrom', userData.jmbgFrom, ['jf1', 'jf2', 'jf3', 'jf4', 'jf5', 'jf6', 'jf7', 'jf8']);
    }
    if (userData.compRegDate) {   
      handleSplitAndSet('compRegDate', userData.compRegDate, ['rd1', 'rd2', 'rd3', 'rd4', 'rd5', 'rd6', 'rd7', 'rd8']);
    }
    if (userData.famJmbgNum1) {
      handleSplitAndSet('famJmbgNum1', userData.famJmbgNum1, ['f11', 'f12', 'f13', 'f14', 'f15', 'f16', 'f17', 'f18', 'f19', 'f110', 'f111', 'f112', 'f113']);
    }
    if (userData.famJmbgNum2) {
      handleSplitAndSet('famJmbgNum2', userData.famJmbgNum2, ['f21', 'f22', 'f23', 'f24', 'f25', 'f26', 'f27', 'f28', 'f29', 'f210', 'f211', 'f212', 'f213']);
    }
    if (userData.famJmbgNum3) {
      handleSplitAndSet('famJmbgNum3', userData.famJmbgNum3, ['f31', 'f32', 'f33', 'f34', 'f35', 'f36', 'f37', 'f38', 'f39', 'f310', 'f311', 'f312', 'f313']);
    }
    if (userData.famJmbgNum4) {
      handleSplitAndSet('famJmbgNum4', userData.famJmbgNum4, ['f41', 'f42', 'f43', 'f44', 'f45', 'f46', 'f47', 'f48', 'f49', 'f410', 'f411', 'f412', 'f413']);
    }
    if (userData.famJmbgNum5) {
      handleSplitAndSet('famJmbgNum5', userData.famJmbgNum5, ['f51', 'f52', 'f53', 'f54', 'f55', 'f56', 'f57', 'f58', 'f59', 'f510', 'f511', 'f512', 'f513']);
    }
    if (userData.pib) {
      handleSplitAndSet('pib', userData.pib, ['p1', 'p2', 'p3', 'p4', 'p5', 'p6', 'p7', 'p8', 'p9', 'p10', 'p11', 'p12', 'p13']);
    }
  };


  const listBillType=['Tekući račun','Devizni račun'];
  const listBankName=['Adriatic Bank','CKB','ERSTE Bank','Hipotekarna Banka','Lovćen Banka','NLB Banka','Prva Banka','Zapad Bank','Ziraat Bank'];

  const menuData = [
    { title: "template_1", items: ["Punomoc za DOO i Odluku", "1_0 - Godišnja ponuda produženje", "1_1 - Godišnja ponuda prvi","1_2 - Izjava za platu director","1_3 - Izjava za platu porodica","1_4 -  Zahtjev porezko uverenje","1_5 - Zahtjev_radna_knjizica"] },
    { title: "template_2", items: ["2_0 - Fond za zdravstveno osiguranje", "2_1 - JPR", "2_2 - Ovlašenje za porezku","2_3 - Ugovor o radu","2_4 - Ugovor o radu Budva","2_5 - Zahtjev na izvod iz CRPS-a"] },
    { title: "template_3", items: ["3_0 - Ovlaščenje za poštu", "3_1 - Zahtjev za fiskalizaciju","3_2 - Zahtjev za token","3_4 - Zahtjev za otvaranje računa PL sve Lovcen"] },
    { title: "template_4", items: ["4_0 - Izjava za raskid ugovora o radu", "4_1 - Otkaz ugovora o radu","4_2 - Sporazum o prestanku radnog odnosa"] },
    { title: "template_5", items: ["5_0 - Pozajmica", "5_1 - Token punomoć","5_2 - Virmani","5_3 - Zahtjev na poresko uverenje","5_4 - Pozajmica sa zavodnom pečatom"] },
    { title: "template_6", items: ["6_0 - Izjava likvidacija", "6_1 - Punomoć likvidacija"] },
  ];







































  return (
    <div className="App">
      <header className="App-header">
        <h1>Document Generator</h1>
      </header>
      <main>
      <>

      </>

      <div id='headButtons'>
      <div>
          <NestedDropdown sections={menuData} onChange={handleTemplateChange} />
        </div>

        <div id='authBlick'>
          <Register/>
          <Login updateState={handleUserDataUpdate} logged={isUserLoginDataUpdate} />
          <SaveData userData={data} setUserData={setData} />
          <SaveSubUserData userData={data} setUserData={setData} />
        </div>

        <div>
          
        </div>
      </div>
      
        <div>
        <form /* className='FormStyle' */>

    <label style={{fontSize:'25px'}}>Личные данные</label>
    <div className='InputSection'>

        <label>Имя</label>
      <input 
        type="text" 
        name="name" 
        value={data.name} 
        onChange={handleChange} 
        className={isFieldRequired('name') ? 'highlight' : ''}
      />
      <label>Фамилия</label>
      <input 
        type="text" 
        name="surName" 
        value={data.surName} 
        onChange={handleChange} 
        className={isFieldRequired('surName') ? 'highlight' : ''}
      />
      <label>Имя Отца</label>
      <input 
        type="text" 
        name="fathName" 
        value={data.fathName} 
        onChange={handleChange} 
        className={isFieldRequired('fathName') ? 'highlight' : ''}
      />
      <label>Пол</label>
      <div>
        <label>
          <input
            type="checkbox"
            name="male"
            checked={data.male === 'V'}
            onChange={handleGenderChange}
          />
          Мужской
        </label>
        <label>
          <input
            type="checkbox"
            name="female"
            checked={data.female === 'V'}
            onChange={handleGenderChange}
          />
          Женский
        </label>
      </div>
<label>Дата Рождения</label>
<input 
  type="date" 
  name="birthDate" 
  value={data.birthDate} 
  onChange={handleChange} 
  className={isFieldRequired('replaceBirthDate') ? 'highlight' : ''} 
/>
<label>Телефон</label>
<input 
  type="tel" 
  name="tel" 
  value={data.tel} 
  onChange={handleChange} 
  className={isFieldRequired('tel') ? 'highlight' : ''} 
/>
<label>Email</label>
<input 
  type="email" 
  name="email" 
  value={data.email} 
  onChange={handleChange} 
  className={isFieldRequired('email') ? 'highlight' : ''} 
/>
<label>Номер Трудовой Книжки</label>
<input 
  type="text" 
  name="workBookNum" 
  value={data.workBookNum} 
  onChange={handleChange} 
  className={isFieldRequired('workBookNum') ? 'highlight' : ''} 
/>
<label>Номер Счета ваш</label>
<input 
  type="text" 
  name="billNum" 
  value={data.billNum} 
  onChange={handleChange} 
  className={isFieldRequired('billNum') ? 'highlight' : ''} 
/>
<label>название банка</label>
      <input 
      list='bankName-list'
        type="text" 
        name="bankName" 
        value={data.bankName} 
        onChange={handleChange} 
        className={isFieldRequired('bankName') ? 'highlight' : ''}
      />
      <datalist id='bankName-list'>
        {listBankName.map((item,index)=><option key={index} value={item}/>)}
      </datalist>
            <label>тип счёта</label>
      <input 
        list='billType-list'
        name="billType" 
        value={data.billType} 
        onChange={handleChange} 
        className={isFieldRequired('billtype') ? 'highlight' : ''}
      />
      <datalist id='billType-list'>
        {listBillType.map((item,index)=><option key={index} value={item}/>)}
      </datalist>
</div>


















<label style={{fontSize:'20px'}}>загранпаспорт</label>
<div className='InputSmallSection'>
<label>Номер Паспортa</label>
<input 
  type="text" 
  name="passNum" 
  value={data.passNum} 
  onChange={handleChange} 
  className={isFieldRequired('passNum') ? 'highlight' : ''} 
/>
<label>Дата Выдачи</label>
<input 
  type="date" 
  name="issueDate" 
  value={data.issueDate} 
  onChange={handleChange} 
  className={isFieldRequired('replaceIssueDate') ? 'highlight' : ''} 
/>
<label>Выдавший Орган</label>
<input 
  type="text" 
  name="issuingOrgan" 
  value={data.issuingOrgan} 
  onChange={handleChange} 
  className={isFieldRequired('issuingOrgan') ? 'highlight' : ''} 
/>
<label>Место Рождения</label>
<input 
  type="text" 
  name="birthPlace" 
  value={data.birthPlace} 
  onChange={handleChange} 
  className={isFieldRequired('birthPlace') ? 'highlight' : ''} 
/>
</div>



















<label style={{fontSize:'20px'}}>боравак</label>
<div className='InputSmallSection'>
<label>Адрес</label>
<input 
  type="text" 
  name="address" 
  value={data.address} 
  onChange={handleChange} 
  className={isFieldRequired('address') ? 'highlight' : ''} 
/>
{/* странный баг без дублированного input нижний datalist не работает */}
<input style={{display:'none'}} list="listofindexcity"/> 

<label>Ваш город (Населённый пункт для почты)</label>
<input 
  list="listofindexcity"
  type="text" 
  name="city" 
  onChange={handleCityChange} 
  className={isFieldRequired('city') ? 'highlight' : ''}  
/>
<datalist id="listofindexcity">
  {Object.keys(cities).map((cityName) => (
    <option key={cityName} value={cityName} />
  ))}
</datalist>
<label>Почтовый индекс физлица </label>
      <input 
        type="text" 
        name="mailIndex" 
        value={data.mailIndex} 
        autoComplete="off"
        onChange={handleChange} 
        className={isFieldRequired('mailIndex') ? 'highlight' : ''}
      />
<label>Номер JMBG</label>
<input 
  type="text" 
  name="jmbgNum" 
  value={data.jmbgNum} 
  onChange={handleChange} 
  className={isFieldRequired('jmbgNum')||isFieldRequired('j1') ? 'highlight' : ''} 
/>
<label>JMBG дата выпуска</label>
<input 
  type="date" 
  name="jmbgFrom" 
  value={data.jmbgFrom} 
  onChange={handleChange} 
  className={isFieldRequired('replaceJmbgFrom') ? 'highlight' : ''} 
/>
<label>JMBG до какого числа годен</label>
<input 
  type="date" 
  name="jmbgTo" 
  value={data.jmbgTo} 
  onChange={handleChange} 
  className={isFieldRequired('replaceJmbgTo') ? 'highlight' : ''} 
/>
</div>






























<label style={{fontSize:'25px'}}>данные компании</label>
<div className='InputSection'>

<label>PIB</label>
<input 
  type="text" 
  name="pib" 
  value={data.pib} 
  onChange={handleChange} 
  className={isFieldRequired('pib') ? 'highlight' : ''} 
/>
<label>Название Компании</label>
<input 
  type="text" 
  name="compName" 
  value={data.compName} 
  onChange={handleChange} 
  className={isFieldRequired('compName') ? 'highlight' : ''} 
/>
<label>Адрес Компании</label>
<input 
  type="text" 
  name="compAddr" 
  value={data.compAddr} 
  onChange={handleChange} 
  className={isFieldRequired('compAddr') ? 'highlight' : ''} 
/>
<label>Населённый пункт где компания расположена</label>
<input 
  type="text" 
  name="compCity" 
  list="listofindexcity"
  value={data.compCity} 
  onChange={handleCompCityChange} 
  className={isFieldRequired('compCity') ? 'highlight' : ''} 
/>
<datalist id="listofindexcity">
  {Object.keys(cities).map((cityName) => (
    <option key={cityName} value={cityName} />
  ))}
</datalist>
<label>Почтовый индекс юрлица</label>
      <input 
        type="text" 
        name="compMailIndex" 
        value={data.compMailIndex} 
        onChange={handleChange} 
        className={isFieldRequired('compMailIndex') ? 'highlight' : ''}
      />
<label>Улица и Дом Компании</label>
<input 
  type="text" 
  name="compStreetHome" 
  value={data.compStreetHome} 
  onChange={handleChange} 
  className={isFieldRequired('compStreetHome') ? 'highlight' : ''} 
/>

<label>Обштина Компании</label>
<input 
  type="text" 
  name="compMunicipal" 
  value={data.compMunicipal} 
  onChange={handleChange} 
  className={isFieldRequired('compMunicipal') ? 'highlight' : ''} 
/>
<label>Регистрационный Номер Компании</label>
<input 
  type="text" 
  name="compRegNum" 
  value={data.compRegNum} 
  onChange={handleChange} 
  className={isFieldRequired('compRegNum') ? 'highlight' : ''} 
/>
<label>Дата Регистрации Компании</label>
<input 
  type="date" 
  name="compRegDate" 
  value={data.compRegDate} 
  onChange={handleChange} 
  className={isFieldRequired('replaceDateRegisterComp') ? 'highlight' : ''} 
/>
<label>Номер Счета Компании</label>
<input 
  type="text" 
  name="compBillNum" 
  value={data.compBillNum} 
  onChange={handleChange} 
  className={isFieldRequired('compBillNum') ? 'highlight' : ''} 
/>
</div>
























<label style={{fontSize:'25px'}}>члены семьи</label>
<div className='InputSection'>
<label>Фамилия Члена Семьи 1</label>
      <input 
        type="text" 
        name="famName1" 
        value={data.famName1} 
        onChange={handleChange} 
        className={isFieldRequired('famName1') ? 'highlight' : ''}
      />
      <label>Номер Паспорт Члена Семьи 1</label>
      <input 
        type="text" 
        name="famPassNum1" 
        value={data.famPassNum1} 
        onChange={handleChange} 
        className={isFieldRequired('famPassNum1') ? 'highlight' : ''}
      />
      <label>Член Семьи 1</label>
      <input 
        type="text" 
        name="famMember1" 
        value={data.famMember1} 
        onChange={handleChange} 
        className={isFieldRequired('famMember1') ? 'highlight' : ''}
      />
      <label>Номер Джмбг Члена Семьи 1</label>
      <input 
        type="text" 
        name="famJmbgNum1" 
        value={data.famJmbgNum1} 
        onChange={handleChange} 
        className={isFieldRequired('famJmbgNum1') ? 'highlight' : ''}
      />
      <label>Фамилия Члена Семьи 2</label>
      <input 
        type="text" 
        name="famName2" 
        value={data.famName2} 
        onChange={handleChange} 
        className={isFieldRequired('famName2') ? 'highlight' : ''}
      />
      <label>Номер Паспорт Члена Семьи 2</label>
      <input 
        type="text" 
        name="famPassNum2" 
        value={data.famPassNum2} 
        onChange={handleChange} 
        className={isFieldRequired('famPassNum2') ? 'highlight' : ''}
      />
      <label>Член Семьи 2</label>
      <input 
        type="text" 
        name="famMember2" 
        value={data.famMember2} 
        onChange={handleChange} 
        className={isFieldRequired('famMember2') ? 'highlight' : ''}
      />
      <label>Номер Джмбг Члена Семьи 2</label>
      <input 
        type="text" 
        name="famJmbgNum2" 
        value={data.famJmbgNum2} 
        onChange={handleChange} 
        className={isFieldRequired('famJmbgNum2') ? 'highlight' : ''}
      />
      <label>Фамилия Члена Семьи 3</label>
      <input 
        type="text" 
        name="famName3" 
        value={data.famName3} 
        onChange={handleChange} 
        className={isFieldRequired('famName3') ? 'highlight' : ''}
      />
      <label>Номер Паспорт Члена Семьи 3</label>
      <input 
        type="text" 
        name="famPassNum3" 
        value={data.famPassNum3} 
        onChange={handleChange} 
        className={isFieldRequired('famPassNum3') ? 'highlight' : ''}
      />
      <label>Член Семьи 3</label>
      <input 
        type="text" 
        name="famMember3" 
        value={data.famMember3} 
        onChange={handleChange} 
        className={isFieldRequired('famMember3') ? 'highlight' : ''}
      />
      <label>Номер Джмбг Члена Семьи 3</label>
      <input 
        type="text" 
        name="famJmbgNum3" 
        value={data.famJmbgNum3} 
        onChange={handleChange} 
        className={isFieldRequired('famJmbgNum3') ? 'highlight' : ''}
      />
      <label>Фамилия Члена Семьи 4</label>
      <input 
        type="text" 
        name="famName4" 
        value={data.famName4} 
        onChange={handleChange} 
        className={isFieldRequired('famName4') ? 'highlight' : ''}
      />
      <label>Номер Паспорт Члена Семьи 4</label>
      <input 
        type="text" 
        name="famPassNum4" 
        value={data.famPassNum4} 
        onChange={handleChange} 
        className={isFieldRequired('famPassNum4') ? 'highlight' : ''}
      />
      <label>Член Семьи 4</label>
      <input 
        type="text" 
        name="famMember4" 
        value={data.famMember4} 
        onChange={handleChange} 
        className={isFieldRequired('famMember4') ? 'highlight' : ''}
      />
      <label>Номер Джмбг Члена Семьи 4</label>
      <input 
        type="text" 
        name="famJmbgNum4" 
        value={data.famJmbgNum4} 
        onChange={handleChange} 
        className={isFieldRequired('famJmbgNum4') ? 'highlight' : ''}
      />
      <label>Фамилия Члена Семьи 5</label>
      <input 
        type="text" 
        name="famName5" 
        value={data.famName5} 
        onChange={handleChange} 
        className={isFieldRequired('famName5') ? 'highlight' : ''}
      />
      <label>Номер Паспорт Члена Семьи 5</label>
      <input 
        type="text" 
        name="famPassNum5" 
        value={data.famPassNum5} 
        onChange={handleChange} 
        className={isFieldRequired('famPassNum5') ? 'highlight' : ''}
      />
      <label>Член Семьи 5</label>
      <input 
        type="text" 
        name="famMember5" 
        value={data.famMember5} 
        onChange={handleChange} 
        className={isFieldRequired('famMember5') ? 'highlight' : ''}
      />
      <label>Номер Джмбг Члена Семьи 5</label>
      <input 
        type="text" 
        name="famJmbgNum5" 
        value={data.famJmbgNum5} 
        onChange={handleChange} 
        className={isFieldRequired('famJmbgNum5') ? 'highlight' : ''}
      />
</div>
























<label style={{fontSize:'25px'}}>даты</label>
<div className='InputSection'>
<label>Текущая Дата</label>
      <input 
        type="date" 
        name="currDate" 
        value={data.currDate} 
        onChange={handleChange} 
        className={isFieldRequired('replaceCurrDate') ? 'highlight' : ''}
      />
      <label>Дата расторжения</label>
      <input 
        type="date" 
        name="termDate" 
        value={data.termDate} 
        onChange={handleChange} 
        className={isFieldRequired('replaceTermDate') ? 'highlight' : ''}
      />
            <label>номер месяца за который платится налог/зарплата например: <b><em>02</em></b> или <b><em>11</em></b> </label>
      <input 
        type="text" 
        name="month" 
        value={data.month} 
        onChange={handleChange} 
        className={isFieldRequired('month') ? 'highlight' : ''}
      />
</div>











        </form>
        </div>
        {selectedTemplate && (
          <button id='ButtonGenerate' onClick={generateDocument}>Preuzmi dokument</button>
        )}
      </main>
        
      {showAppComp ? <UserList onUserSelect={(userData)=>handleUserDataUpdate(userData)}/>:null}
      {isUserLogin ? <><CreateSubmissiveUser/> <ShowSubUsers onUserSelect={(userData)=>handleUserDataUpdate(userData)}/></>:null}
        {/* onUserSelect это заглушка которая заполняет данные пользователя при его выборе, вообще это должно происходить через login, а не в ручную */}
    </div>
  );
};

export default App;  
