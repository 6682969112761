import axios from 'axios';
import React, { useState } from 'react';
import { FormData } from './App';

export const API_URL = 'https://subdomserv.jelenapticina.me'; /* /cgi-sys/defaultwebpage.cgi */
export const tokenSecret = 'JNSDsdfjvjse234djsdpnp345892xzdfhydyk34234';

const register = async (username: string, password: string) => {
  const response = await axios.post(`${API_URL}/register`, { username, password },{ timeout: 70000 });
  if (response.data.token) {
    localStorage.setItem('userToken', response.data.token); // Сохраняем токен
  }
  return response.data.user;
};

export const login = async (username:string, password:string) => {
  const response = await axios.post(`${API_URL}/login`, { username, password },{ timeout: 70000 });
  if (response.data.token) {
    localStorage.setItem('userToken', response.data.token);
  }
  return response.data.user; // Возвращает данные пользователя
};

 const logout = () => {
  localStorage.removeItem('userToken');
};

const savedata=async(userData:FormData):Promise<void>=>{
  try {
    const token = localStorage.getItem('userToken'); // Предполагается, что токен JWT сохранен в localStorage после входа пользователя.
    await axios.post(`${API_URL}/saveUserData`, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      }, timeout: 70000,
    });
  } catch (error) {
    console.error('Error saving user data:', error);
    throw error; // Выбрасываем ошибку, чтобы ее можно было обработать в компоненте
  }
  };

const savesubuserdata=async(userData:FormData):Promise<void>=>{
  try{
    await axios.post(`${API_URL}/saveSubUserData`,userData, {
      timeout:70000
    })
  }
  catch(error){
    console.error('error saveSubData',error);
    throw error;
  }

  }

  const CreateSubmissiveUserPreServerPart=async (submissiveUsername:string,submissivePassword:string)=>{

    
    try{
    const token = localStorage.getItem('userToken')

    
    await axios.post(`${API_URL}/saveSubmissionUser`, 
      { submissiveUsername, submissivePassword },
      { headers: { Authorization: `Bearer ${token}` }, timeout: 70000 }
    );
  }catch(error){
    console.log('error create SubUser',error)
  }
  }

  export const CreateSubmissiveUser = () => {
    const [message, setMessage] = useState<string>('');
    const [error, setError] = useState<string | null>(null); 
    const [submissiveUsername, setSubmissiveUsername] = useState<string>('');
    const [submissivePassword, setSubmissivePassword] = useState<string>(''); 
  
    const handleSubmit = async (event: React.FormEvent) => {
      event.preventDefault(); // Предотвращение перезагрузки при отправке формы
      try {
        await CreateSubmissiveUserPreServerPart(submissiveUsername, submissivePassword);
        setMessage('Submissive user created successfully');
        setError(null);
      } catch (err: any) {
        setError('Error creating submissive user');
        console.error(err);
      }
    };
  
    return (
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="username">Username:</label>
          <input
            id="username"
            type="text"
            value={submissiveUsername}
            onChange={(e) => setSubmissiveUsername(e.target.value)} // Обновление состояния
            required
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            id="password"
            type="password" // Скрытый ввод для пароля
            value={submissivePassword}
            onChange={(e) => setSubmissivePassword(e.target.value)} // Обновление состояния
            required
          />
        </div>
        <button type="submit">Create Submissive User</button>
        {message && <p style={{ color: 'green' }}>{message}</p>}
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </form>
    );
  };
  


export const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await register(username, password);
      setMessage('User registered successfully');
    } catch (error) {
      setMessage('Error registering user');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Username:</label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div>
        <label>Password:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button type="submit">Register</button>
      <p>{message}</p>
    </form>
  );
};






interface LoginProps{
  updateState: (newState: FormData) => void;
  logged:(newState:boolean)=>void;
}

export const Login:React.FC<LoginProps> = ({updateState,logged}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const userData = await login(username, password);
      updateState(userData); // Обновляем состояние пользователя в основном приложении
      logged(true);
      setMessage('Logged in successfully');
    } catch (error) {
      setMessage('Invalid username or password');
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Username:</label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div>
        <label>Password:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button type="submit">Login</button>
      <p>{message}</p>
    </form>
  );
};


interface SaveDataProps {
/*   user: { id: number; username: string }; // Информация о пользователе */
  userData: FormData; // Состояние данных пользователя из App.tsx
  setUserData: React.Dispatch<React.SetStateAction<FormData>>; // Функция для обновления состояния данных пользователя
}


export const SaveData: React.FC<SaveDataProps> = ({ userData, setUserData }) => {
  const [message, setMessage] = React.useState<string>('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await savedata(userData);
      setMessage('User data saved successfully');
    } catch (error) {
      setMessage('Error saving user data');
    }
  };

  return (
    <>
      <button onClick={handleSubmit}>Save Data в пользователя который залогинился/зарегался</button>
      {message && <p>{message}</p>}
    </>
  );
};

export const SaveSubUserData:React.FC<SaveDataProps>=({userData,setUserData})=>{
  const [message,setMessage]=React.useState<string>('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await savesubuserdata(userData);
      setMessage('User data saved successfully');
    } catch (error) {
      setMessage('Error saving user data');
    }
  };

  return(
    <>
          <button onClick={handleSubmit}>Save Data загруженные по кнопке админа </button>
          {message && <p>{message}</p>}
    </>
  )
}

